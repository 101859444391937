<template>
   <div class="flex flex-wrap">
       <item-text title="Preces kods" :text="item.sku"/>
       <item-text title="Mērvienība" :text="item.measure_unit.name"/>
       <item-text title="Modelis" :text="item.name"/>
       <item-text title="Kategorija" :text="item.category.name"/>

       <template v-if="item.default_regional_code">
           <item-text title="Noklusējuma reģionālais kods" :text="item.default_regional_code"/>
       </template>

       <template v-if="item.item_value">
           <item-text title="Pašizmaksa / EUR" :text="item.item_value"/>
       </template>

       <template v-if="item.base_price">
           <item-text title="Pārdošanas bāzes cena / EUR" :text="item.base_price"/>
       </template>

   </div>

</template>

<script>
import ItemText from "@/components/Components/ItemText"
import {mapGetters} from "vuex";

export default {
    name: "ShowCatalogItemDetails",
    components: {
        ItemText,
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            formsForDisplay: 'formsForDisplay',
        }),
    },
    methods: {
        showInWebshop(url) {
            window.open(url, "_blank");
        },
        showForm(formName){
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName){
            return this.formsForDisplay.includes(formName)
        },
    }
}
</script>

<style>

</style>